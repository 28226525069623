import { reactive, readonly } from "vue";

const state = reactive({
    loadedData: false,
    brends: null,
    userInfo: null,
    sourcesOfOrders: null,
    showHeaderWaitningIcon: false,
    allUniqueSourcesOfOrders: [],
});

const setLoadedData = function () {
    state.loadedData = true;
}

const setBrends = function (data) {
    state.brends = data;
}

const setUserInfo = function (data) {
    state.userInfo = data;
}

const setSourcesOfOrders = function (data) {
    state.sourcesOfOrders = data;
}

const getSourceNameById = function(id) {
    
    if(state.sourcesOfOrders) {
        id = parseInt(id);
        for(let item of state.sourcesOfOrders) {
            if( parseInt(item.id) === id ) {
                return item.name;
            }
        }
    }
    return '';
}

const getSourceById = function(id) {
    
    if(state.sourcesOfOrders) {
        id = parseInt(id);
        for(let item of state.sourcesOfOrders) {
            if( parseInt(item.id) === id ) {
                return item;
            }
        }
    }
    return '';
}

const getBrendNameById = function(id) {
    if(state.brends) {
        id = parseInt(id);
        for(let item of state.brends) {
            if( parseInt(item.id) === id ) {
                return item.name;
            }
        }
    }
    return '';
}

const setShowHeaderWaitningIcon = function(value) {
    state.showHeaderWaitningIcon = value;
}

const setAllUniqueSourcesOfOrders = function(value) {
    state.allUniqueSourcesOfOrders = value;
}

const getAllUniqueSourcesOfOrders = function() {
    return state.allUniqueSourcesOfOrders;
}

export default { 
    state: readonly(state)
  , setLoadedData
  , setBrends
  , setUserInfo
  , setSourcesOfOrders
  , getBrendNameById
  , getSourceNameById
  , getSourceById
  , setShowHeaderWaitningIcon
  , setAllUniqueSourcesOfOrders
  , getAllUniqueSourcesOfOrders
};