/* Тип тары */
export default class {
    static PLASTIC_30_ML       = 1;
    static PLASTIC_50_ML       = 2;
    static GLASS_50_ML         = 3;
    static PLASTIC_100_ML      = 4;
    static GLASS_100_ML        = 5;
    static AUTOPARFUME_8_ML    = 6;
    static GLASS_500_ML        = 7;
    static GLASS_30_ML         = 8;
    static GLASS_3_ML          = 9;
    static GLASS_5_ML          = 10;
    static PLASTIC_15_ML       = 11;
    
    static getListWithNames() {
        return [
                { 
                    name: "30 мл пластик"
                  , value: this.PLASTIC_30_ML
                  , gross_weight: 35        // Вес с продуктом (гр)
                  , width: 30               // Ширина (мм)
                  , length: 27              // Длинна (мм)
                  , height: 85              // Высота (мм)
                  , volume: 30              // Объем (мл)
                },
                { 
                    name: "50 мл пластик"
                  , value: this.PLASTIC_50_ML
                  , gross_weight: 50       // Вес с продуктом (гр)
                  , width: 38              // Ширина (мм)
                  , length: 27             // Длинна (мм)
                  , height: 109            // Высота (мм)
                  , volume: 50              // Объем (мл)
                },
                { 
                    name: "50 мл стекло"
                  , value: this.GLASS_50_ML
                  , gross_weight: 180       // Вес с продуктом (гр)
                  , width: 52               // Ширина (мм)
                  , length: 33              // Длинна (мм)
                  , height: 126             // Высота (мм)
                  , volume: 50              // Объем (мл)
                },
                { 
                    name: "100 мл пластик"
                  , value: this.PLASTIC_100_ML
                  , gross_weight: 115       // Вес с продуктом (гр)
                  , width: 35               // Ширина (мм)
                  , length: 35              // Длинна (мм)
                  , height: 159             // Высота (мм)
                  , volume: 100             // Объем (мл)
                },
                { 
                    name: "100 мл стекло"
                  , value: this.GLASS_100_ML
                  , gross_weight: 290       // Вес с продуктом (гр)
                  , width: 63               // Ширина (мм)
                  , length: 36              // Длинна (мм)
                  , height: 140             // Высота (мм)
                  , volume: 100             // Объем (мл)
                },
                {
                    name: "Автопарфюм 8 мл"
                  , value: this.AUTOPARFUME_8_ML
                  , gross_weight: 35        // Вес с продуктом (гр)
                  , width: 40               // Ширина (мм)
                  , length: 30              // Длинна (мм)
                  , height: 88              // Высота (мм)
                  , volume: 8               // Объем (мл)
                },
                {
                    name: "500 мл стекло"
                  , value: this.GLASS_500_ML
                  , gross_weight: 1030      // Вес с продуктом (гр)
                  , width: 80               // Ширина (мм)
                  , length: 80              // Длинна (мм)
                  , height: 170             // Высота (мм)
                  , volume: 500             // Объем (мл)
                },
                {
                    name: "30 мл стекло"
                  , value: this.GLASS_30_ML
                  , gross_weight: 133      // Вес с продуктом (гр)
                  , width: 24              // Ширина (мм)
                  , length: 45             // Длинна (мм)
                  , height: 115            // Высота (мм)
                  , volume: 30             // Объем (мл)
                },
                {
                    name: "3 мл стекло"
                  , value: this.GLASS_3_ML
                  , gross_weight: 6        // Вес с продуктом (гр)
                  , width: 12              // Ширина (мм)
                  , length: 12             // Длинна (мм)
                  , height: 60             // Высота (мм)
                  , volume: 3              // Объем (мл)
                },
                {
                    name: "5 мл стекло"
                  , value: this.GLASS_5_ML
                  , gross_weight: 11       // Вес с продуктом (гр)
                  , width: 12              // Ширина (мм)
                  , length: 12             // Длинна (мм)
                  , height: 80             // Высота (мм)
                  , volume: 5              // Объем (мл)
                },
                {
                    name: "15 мл пластик"
                  , value: this.PLASTIC_15_ML
                  , gross_weight: 23       // Вес с продуктом (гр)
                  , width: 22              // Ширина (мм)
                  , length: 22             // Длинна (мм)
                  , height: 85             // Высота (мм)
                  , volume: 15             // Объем (мл)
                },
        ]
    }
    
    static getNameByType(v) {
        let list = this.getListWithNames();
        for(let item of list) {
            if( v == item.value ) {
                return item.name;
            }
        }
        
        return '';
    }
    
    static getRowByType(v) {
        let list = this.getListWithNames();
        for(let item of list) {
            if( v == item.value ) {
                return item;
            }
        }
        
        return '';
    }
}