<!-- Показывает историю доставки -->
<template>
    <div id="form-history-delivery-root">
        <div id="form-history-delivery">
            <div id="form-history-delivery-body">
                <div id="form-history-delivery-title">История доставки {{ page.form.ttnNumber ? ' (' + page.form.ttnNumber + ')' : '' }}</div>
                <div id="form-history-delivery-list">
                    <template v-if="page.form.list">
                        <div v-for="(item, index) in page.form.list" 
                             :key="index"
                             class="form-history-delivery-list-item">
                            <div class="form-history-delivery-list-item-date-time">{{ timeConverter(item.date_time) }}</div>
                            <div class="form-history-delivery-list-item-status-name">{{ item.name }}</div>
                        </div>
                    </template>
                    <template v-else>
                        <span>{{ page.form.list != null ? 'Список пуст' : '' }}</span>
                    </template>
                </div>
            </div>
            <div id="form-history-delivery-close-form" @click="emit('close')"></div>
            <div id="form-history-delivery-error">{{ page.form.error_string }}</div>
            <div id="form-history-delivery-footer">
                <button class="btn-style-2 noselect" @click="emit('close')">Закрыть</button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { reactive, defineEmits, defineExpose, inject } from "vue";
    import DeliveryMethod from '../../DeliveryMethod.js'
    import { timeConverter } from '../../global.js'
    
    const emit = defineEmits(['close']);
    let globalConfig = inject('globalConfig');
        
    let page = reactive({
        form: {
            ttnNumber: '',
            error_string: '',
            list: null,
        }
    });
    
    defineExpose({
        getInfo
    });
    
    function getInfo(ttn_info, delivery_method, order_id) {
        
        page.form.list = null;
        page.form.ttnNumber = DeliveryMethod.getNameByType(delivery_method) 
                + ': '
                + (delivery_method == DeliveryMethod.SDEK ? ttn_info.cdek_number : ttn_info.barcode);
        
        if(delivery_method == DeliveryMethod.SDEK) {
            globalConfig.$bus.emit('showLoading', "Получение данных доставки...");
        
            globalConfig.$postRequest(globalConfig.$config.urlKladr_getHistoryForSdek, {
                number: ttn_info.cdek_number,
                order_id: order_id
            }, localStorage.apiToken)
            .then((data) => {
                globalConfig.$bus.emit('hideLoading');
                
                let result = data.data;
        
                if(
                        result
                     && result.requests
                     && result.requests.length > 0
                     && result.requests[0].state
                     && result.requests[0].state == 'SUCCESSFUL'
                 ) {
                    if(
                            result.entity
                         && result.entity.uuid
                    ) {
                        let list = [];
                        
                        for(let row of result.entity.statuses) {
                            list.push({
                                name: row.name,
                                date_time: (new Date(row.date_time)).getTime() / 1000,
                            });
                        }
                        
                        page.form.list = list;
                    }
                    else {
                        page.form.list = [];
                    }
                }
                else {
                    page.form.list = [];
                }
            })
            .catch(() => {
                globalConfig.$bus.emit('hideLoading');
            });
        }
        else {
            
            globalConfig.$bus.emit('showLoading', "Получение данных доставки...");
        
            globalConfig.$postRequest(globalConfig.$config.urlApiPost_checkOrder, {
                id: ttn_info.barcode
            }, localStorage.apiToken)
            .then((data) => {
                globalConfig.$bus.emit('hideLoading');
        
                let res = data.operationsByRpo.reverse();
                
                if( res && res.length > 0 ) {
                    let list = [];

                    for(let row of res) {
                        list.push({
                            name: row.OperationParameters.OperType.Name + (
                                    row.OperationParameters.OperAttr.Name
                                    ? ' (' + row.OperationParameters.OperAttr.Name + ')'
                                    : ''
                                    ),
                            date_time: (new Date(row.OperationParameters.OperDate)).getTime() / 1000,
                        });
                    }

                    page.form.list = list;
                }
                else {
                    page.form.list = [];
                }
            })
            .catch(() => {
                globalConfig.$bus.emit('hideLoading');
            });
        }
    }
</script>

<style scoped>
    #form-history-delivery-root {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.1);
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
    #form-history-delivery {
        display: flex;
        flex-direction: column;
        background: #FFF;
        padding: 16px;
        box-shadow: 0 0 10px #555;
        border-radius: 5px;
        position: relative;
        min-width: 500px;
        margin-bottom: 100px;
        min-height: 300px;
        max-height: 550px;
        /*overflow-y: auto;*/
    }
    #form-history-delivery-body {
        flex-grow: 1;
    }
    #form-history-delivery-close-form {
        width: 16px;
        height: 16px;
        cursor: pointer;
        opacity: 0.5;
        background-image: url(~@/assets/images/close.svg);
        background-size: 16px 16px;
        background-repeat: no-repeat;
        position: absolute;
        top: 10px;
        right: 10px;
    }
    #form-history-delivery-close-form:hover {
        opacity: 1;
    }
    
    #form-history-delivery-footer {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    #form-history-delivery-footer > button {
        margin-left: 10px;
    }
    #form-history-delivery-error {
        font-size: 12px;
        color: red;
        margin-top: 12px;
    }
    .form-history-delivery-choose-date-period {
        display: flex;
        align-items: center;
    }
    .form-history-delivery-choose-date-period > span {
        display: inline-block;
        margin-right: 10px;
    }
    .form-history-delivery-choose-date-period > span:not(:first-child) {
        margin-left: 10px;
    }
    #form-history-delivery-title {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: bold;
    }
    #form-history-delivery-list {
        padding: 5px;
        max-height: 300px;
        overflow: auto;
    }
    .form-history-delivery-list-item {
        margin-bottom: 10px;
    }
    .form-history-delivery-list-item-status-name {
        font-weight: bold;
    }
    .form-history-delivery-list-item-date-time {
        margin-bottom: 2px;
    }
</style>