import { createRouter, createWebHistory } from 'vue-router'

import PageLogin from '@/components/PageLogin.vue'
import NotFound from '@/components/NotFound.vue'
import CpanelMain from '@/components/pages/CpanelMain.vue'

const routerHistory = createWebHistory()
const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/',
      name: 'CpanelMain',
      component: CpanelMain
    },
    {
      path: '/login',
      name: 'PageLogin',
      component: PageLogin
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
  ]
});

export default router