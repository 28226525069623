import { createApp } from 'vue'

import VueTheMask from 'vue-the-mask'

import storage from "@/storage";

import Main from '@/Main.vue'
import router from '@/router'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import { g_config } from './global.js'
import { g_postRequest } from './global.js'
import { g_putRequest } from './global.js'
import { g_getRequest } from './global.js'
import { g_deleteRequest } from './global.js'
import { toast } from './toast.js'

import './assets/css/reset.css';
import './assets/css/styles.css';
import './assets/css/chart_9.css';

import $bus from './event.js';

const app = createApp(Main);

app.provide('globalConfig', {
    $bus: $bus,
    $config: g_config,
    $postRequest: g_postRequest,
    $putRequest: g_putRequest,
    $getRequest: g_getRequest,
    $deleteRequest: g_deleteRequest,
    $toast: toast,
    infoDiskInfo: null,
});

app.provide('storage', storage);

app.use(router);
app.use(VueTheMask);

const VueTelInputOptions = {
    mode: "international",
    defaultCountry: g_config['country'],
    onlyCountries: g_config['countrys'],
    inputOptions: {
        placeholder: 'Введите номер телефона'
    }
}
app.use(VueTelInput, VueTelInputOptions);
app.mount('#app');