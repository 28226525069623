/* Типы промокодов */
export default class {
    static GENERAL                = 1; // Общий
    static CATEGORIES             = 2; // Для категорий
    static FOR_ASSORTMENT         = 3; // Для ассортимента
    static FOR_ARTICLES           = 4; // Для артикулов
    
    static getListWithNames() {
        return [
              { name: "Общий", value: this.GENERAL }
            , { name: "Для категорий", value: this.CATEGORIES }
            , { name: "Для ассортимента", value: this.FOR_ASSORTMENT }
            , { name: "Для артикулов", value: this.FOR_ARTICLES }
        ];
    }
    
    static getNameByType(v) {
        let list = this.getListWithNames();
        for(let item of list) {
            if( v == item.value ) {
                return item.name;
            }
        }
        
        return '';
    }
}
