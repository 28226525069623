<!-- Форма авторизации -->
<template>
    <div id="form-content">
        <div id="form-login">
            <div id="form-login-body">
                <div id="form-login-body-logo-c">
                    <img id="form-login-body-logo" src="~@/assets/images/logo.svg" />
                </div>
                <span id="form-login-title">Авторизация</span>
                <div>
                    <vue-tel-input 
                               class="form-login-input form-login-input-phone"
                               v-model="formData.phone"
                               mode="international"
                               :class="{'form-login-error': formData.phone.length != 0 && ! formData.validatePhone}"
                               @validate="validatePhone"></vue-tel-input>
                </div>
                <div>
                    <input class="form-login-input input-style-1" 
                           type="password" 
                           maxlength="50"
                           v-model="formData.password"
                           :class="{'form-login-error': formData.errors.password}"
                           @keyup.enter="loginUser()"
                           />
                </div>
                
            </div>
            <div id="form-login-footer">
                <button class="btn-style-1" 
                        v-if="formData.timeWait == null"
                        @click="loginUser()">Вход</button>
                
                <span v-if="formData.timeWait != null"
                      id="form-login-input-time-wait">Ожидание: {{ formData.timeWait }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { reactive, onMounted, onUnmounted, inject } from 'vue'
    import { useRouter } from 'vue-router'
    import { toast } from '@/toast.js'
    import { getUnixTimestamp } from '@/global.js'
    import { secondsToString } from '@/global.js'
    
    document.title = "Авторизация - Личный кабинет клиента PdParis";
    let globalConfig = inject('globalConfig');
    
    const router = useRouter()
    
    let timer = {
        interval: null,
        timeEnd: null,
    };
    
    let formData = reactive({
        phone: '',
        password: '',
        validatePhone: false,
        timeWait: null,
        errors: {
            phone: false,
            password: false
        }
    });
    
    onMounted(() => {
    });
    
    onUnmounted(() => {
        if(timer.interval) {
            clearInterval(timer.interval);
        }
    });
    
    function validatePhone(data) {
        if(data.countryCode) {
            formData.validatePhone = data.valid;
            
            console.log(formData.phone.length != 0 && ! formData.validatePhone);
        }
        
        clearInterval(timer.interval);
        timer.interval     = null;
        formData.timeWait  = null;
    }
    
    let loginUser = () => {
        formData.errors.phone     = false;
        formData.errors.password  = false;
        
        if( ! formData.validatePhone ) {
            return;
        }
        
        formData.password = formData.password.trim();
        
        if(formData.password.length < 6) {
            formData.errors.password = true;
            return;
        }
        
        globalConfig.$bus.emit('showLoading', "Авторизация...");
        
        globalConfig.$postRequest(globalConfig.$config.urlLogin, {
            login: formData.phone,
            pass: formData.password
        })
        .then((data) => {
            console.log(data);
            localStorage.apiToken = data.data.token;
            router.push('/');
            
            globalConfig.$bus.emit('onAuthorization');
            globalConfig.$bus.emit('hideLoading');
        })
        .catch((data) => {
            console.log(data);
            globalConfig.$bus.emit('hideLoading');
            
            if(data.type_error == 'api' && data.data.error_key == 'not-right-login-or-password') {
                toast.toast( { type: 'text', value: "Пользователь не найден" }, { settings: { duration: 5300 } });
            }
            else if(data.type_error == 'api' && data.data.error_key == 'limit-auth') {
                toast.toast( { type: 'text', value: "Ограничение неудачных попыток входа" }, { settings: { duration: 5300 } });
                
                timer.timeEnd = getUnixTimestamp() + data.data.timeWait;
                
                timer.interval = setInterval(() => {
                    let _now = getUnixTimestamp();
                    
                    if(timer.timeEnd - _now < 0) {
                        clearInterval(timer.interval);
                        timer.interval     = null;
                        formData.timeWait  = null;
                        return;
                    }
                    
                    formData.timeWait = secondsToString(timer.timeEnd - _now);
                }, 1000);
            }
        });
    }
    
</script>

<style>
    html, body {
        background: #eee;
    }
</style>

<style scoped>
    h3 {
        font-size: 14px;
    }
    #form-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }
    #form-login {
        display: flex;
        flex-direction: column;
        background: #f9f9f9;
        border: 1px solid #CCC;
        border-radius: 5px;
        min-width: 340px;
        box-shadow: 0 0 8px #aaa;
        margin-bottom: 100px;
    }
    #form-login-title {
        text-align: center;
        margin-top: 5px;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 1.1px;
    }
    #form-login-body {
        padding: 16px 20px;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #ccc;
    }
    #form-login-footer {
        border-top: 1px solid #fff;
        padding: 10px 20px;
        text-align: right;
    }
    .form-login-input {
        margin-bottom: 10px;
        width: 100%;
        height: 33px;
    }
    .btn-style-1 {
        font-size: 14px;
    }

    .form-login-error {
        border-color: red !important;
    }
    #form-login-body-logo-c {
        text-align: center;
        margin: 5px 0;
    }
    #form-login-body-logo {
        height: 30px;
        width: auto;
    }
    .form-login-input-phone {
        height: 33px;
    }
    #form-login-input-time-wait {
        font-size: 12px;
        display: inline-block;
        margin: 3px 0;
    }
</style>
