<!-- Панель управления -->
<template>
    <div id="cpanel">
        <Header />
        <div id="body">
            <div id="body-left-menu">
                <LeftMenu :active="Pages.MAIN" />
            </div>
            <div id="body-right-content">
                <Main />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    
    import Header from '../Header.vue'
    import LeftMenu from '../LeftMenu.vue'
    import Main from '../modules/Main.vue'
    
    import Pages from '../../Pages.js'
    
    document.title = "Личный кабинет клиента PdParis";

    const router = useRouter();
    
    onMounted(() => {
        if( ! localStorage.apiToken ) {
            router.push('/login');
            return;
        }
    });
</script>

<style>
    html, body {
        background: #fefefe;
    }
</style>

<style scoped>
    #cpanel {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
    #body {
        flex-grow: 1;
        display: flex;
    }
    #body-left-menu {
        width: 200px;
        min-width: 200px;
        border-right: 1px solid #CCC;
    }
    #body-right-content {
        flex-grow: 1;
    }
</style>