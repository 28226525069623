/**
 * Способ доставки
 */
export default class {
    static RUSSIAN_POST  = 1;  // Почта России
    static SDEK          = 2;  // СДЭК
    
    static getListWithNames() {
        return [
              { name: "Почта России", value: this.RUSSIAN_POST }
            , { name: "СДЭК", value: this.SDEK }
        ]
    }
    
    static getNameByType(v) {
        let list = this.getListWithNames();
        for(let item of list) {
            if( v == item.value ) {
                return item.name;
            }
        }
        
        return '';
    }
    
    static getItemByType(v) {
        let list = this.getListWithNames();
        for(let item of list) {
            if( v == item.value ) {
                return item;
            }
        }
        
        return '';
    }
}