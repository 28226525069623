import TypePromoCodes from './TypePromoCodes.js'

// Расчеты для списка товаров
// v 0.2
export default class {
    
    // Цена с учетом скидки по промокоду
    static getPriceWithDiscond(promocode, item) {
        if(promocode) {

            if(
                    promocode.type == TypePromoCodes.CATEGORIES
                 && promocode.data.includes(parseInt(item.products_category_id))
            ) {
                return Math.ceil(item.price - promocode.discount * item.price / 100);
            }
            else if(
                    promocode.type == TypePromoCodes.FOR_ASSORTMENT
                 && promocode.data.find(id => id == parseInt(item.products_assortment_id)) != undefined
            ) {
                return Math.ceil(item.price - promocode.discount * item.price / 100);
            }
            else if(
                    promocode.type == TypePromoCodes.FOR_ARTICLES
                 && promocode.data.find((item_data) => {

                        if(item_data.type === TypePromoCodes.TYPE_FOR_ARTICLES_STRING) {
                            return item_data.value == item.products_assortment_article_number;
                        }
                        else {
                            try {
                                let re = new RegExp(item_data.value);
                                return re.test(item.products_assortment_article_number);
                            }
                            catch {
                                return false;
                            }
                        }
                    })
            ) {
                return Math.ceil(item.price - promocode.discount * item.price / 100);
            }
        }

        return item.price;
    }
    
    // Проверит есть ли скидка для позоции из списка товаров
    static haveDiscond(promocode, item) {

        if(item.price == 0) {
            return false;
        }

        if(promocode) {
            if(promocode.type == TypePromoCodes.CATEGORIES) {
                return promocode.data.includes(parseInt(item.products_category_id));
            }
            else if(promocode.type == TypePromoCodes.FOR_ASSORTMENT) {
                return promocode.data.find(id => id == parseInt(item.products_assortment_id)) != undefined;
            }
            else if(promocode.type == TypePromoCodes.FOR_ARTICLES) {
                return promocode.data.find((item_data) => {

                    if(item_data.type === TypePromoCodes.TYPE_FOR_ARTICLES_STRING) {
                        return item_data.value == item.products_assortment_article_number;
                    }
                    else {
                        try {
                            let re = new RegExp(item_data.value);
                            return re.test(item.products_assortment_article_number);
                        }
                        catch {
                            return false;
                        }
                    }
                });
            }
        }

        return false;
    }
    
    // Вернут итоговую стоимость для заказа без учетом скидок
    static getTotalSummWithoutDiscond(rows) {
        let result = 0.0;
        for(let item of rows) {
            let value = 0;
            value = parseFloat(item.price) * (parseInt(item.count) - parseInt(item.count_in_present));
            result += value;
        }

        return Math.ceil(result);
    }
    
    // Вернут итоговую стоимость для заказа с учетом скидок и подарков
    static getTotalSum(promocode, fixed_discount, certificate, rows) {
        let result = 0.0;
        
        if(certificate && Number.isInteger(certificate)) {
            for(let item of rows) {
                result += parseFloat(item.price) * (parseInt(item.count) - parseInt(item.count_in_present));
            }
            
            result -= certificate;
            if(result < 0) {
                result = 0;
            }
        }
        else {
            for(let item of rows) {
                let value = 0;

                if(this.haveDiscond(promocode, item)) {
                    value = parseFloat(this.getPriceWithDiscond(promocode, item))
                            * (
                                  parseInt(item.count)
                                - parseInt(item.count_in_present)
                            );
                }
                else {
                    value = parseFloat(item.price) * (parseInt(item.count) - parseInt(item.count_in_present));
                }

                result += value;
            }

            if(promocode && promocode.type == TypePromoCodes.GENERAL) {
                result -= result * promocode.discount / 100;
            }
        }

        let totalCount = rows.reduce((sum, item) => sum + parseInt(item.count), 0);
        
        if(fixed_discount && totalCount >= parseInt(fixed_discount.min_count)) {
            result -= result * parseInt(fixed_discount.discount) / 100;
        }

        return Math.ceil(result);
    }
    
    // Сколько всего может быть подарков, исходя из схемы (см настройки источника)
    static getCountFree(count_product, count_pay, count_present) {
        let count_free = 0;
        if(count_pay == 0) {
            return 0;
        }
        
        for(let i = count_product; i > 0;) {
            i -= count_pay;
            for(let j = 0; j < count_present && i > 0; j++) {
                i--;
                count_free++;
            }
        }
        return count_free;
    }

    // Найдет все позиции с подарками и их количество
    static calculatePresents(source_data, rows) {
        let newFirst  = [];
        let newSecond = [];

        for(let item of rows) {
            if(item.price > 0 && source_data.categories.includes(item.products_category_id)) {
                newFirst.push(item);
            }
            else {
                newSecond.push(item);
            }
        }

        newFirst.orderBy([
            {key: 'price'},
            {key: 'volume_ml'},
        ]);

        let totalCountProducts = newFirst.reduce((sum, row) => sum + row.count, 0);
        let needCountPresent = this.getCountFree(totalCountProducts, source_data.gift_scheme[0], source_data.gift_scheme[1]);
        let present_count = 0;

        for(let item of newFirst) {

            item.count_in_present = 0;

            for(let j = 0; j < item.count && present_count < needCountPresent; j++) {
                item.count_in_present += 1;
                present_count += 1;
            }
        }

        return [...newFirst, ...newSecond];
    }
}