<!-- Главный модуль -->
<template>
    <router-view />
    <loading-div />
</template>

<script setup>
    import { onMounted, inject } from 'vue'
    import LoadingDiv from '@/components/LoadingDiv.vue'
    
    let storage = inject('storage');
    
    let globalConfig = inject('globalConfig');
    
    // загружаем данные
    function loadListBrends() {
        return globalConfig.$getRequest(globalConfig.$config.urlBrendsLoadAll, localStorage.apiToken)
        .then((data) => {
            storage.setBrends(data.data);
        });
    }
    
    function getUserInfo() {
        return globalConfig.$getRequest(globalConfig.$config.urlUserInfo, localStorage.apiToken)
        .then((data) => {
            storage.setUserInfo(data.data);
        });
    }
    
    function loadAllData() {
        globalConfig.$bus.emit('showLoading', "Получение данных...");
        
        // загружем все данные
        Promise.all([
              loadListBrends()
            , getUserInfo()
        ])
        .then(() => {
            globalConfig.$bus.emit('hideLoading');
        })
        .catch(() => {
            globalConfig.$bus.emit('hideLoading');
        });
    }
    
    globalConfig.$bus.on('onAuthorization', loadAllData);
    
    onMounted(() => {
        if( localStorage.apiToken ) {
            loadAllData();
        }
    });
</script>

<style>
#app {
    color: #2c3e50;
    min-height: 100vh;
}
</style>
