/**
 *  Статусы состояния для заказа
 * 
 *  Для частичной предоплаты, возможны только два варианта:
        = Оформлен-наложенный платеж
        = Оформлен-наложенный платеж + БД

    Для полной предоплаты или "Полная предоплата на карту"
        = Оформлен-оплачен
        = Оформлен-оплачен + БД
    
    Вначале самом, менеджер, может выбрать 4 пути:
        - Бесплатный заказ (сразу уходит на склад (это досыл клиенту или отправка блогерам))
        - Оформлен-наложенный платеж (БД) (сразу на склад уходит)
        - Частичная предоплата (через KS -> Оформлен-наложенный платеж (БД))
        - Полная предоплата (через KS -> Оформлен-оплачен (БД))
        
    БД подразумеваем что все расходы на доставку, оплачивает компания
 */
export default class {
    static CHECK_PHONE                          = 1;   // Проверка телефона
    static NEW_ORDER                            = 2;   // Новый заказ
    static IN_PROGRESS                          = 3;   // В обработке
    static INVALID_NUMBER                       = 4;   // Некорректный номер
    static DOUBLE                               = 5;   // Дубль
    static ORDER_FOR_COMMUNICATION              = 6;   // Заказ для связи
    static ISSUED_PAID_FOR                      = 7;   // Оформлен-оплачен (оплата 100%)
    static ISSUED_PAID_FOR_BD                   = 8;   // Оформлен-оплачен + БД (оплата 100% без учета доставки)
    static ISSUED_CASH_ON_DELIVERY              = 9;   // Оформлен-наложенный платеж (предворительно оплата + часть при получении или оплата 100% при получении посылки с учетом доставки)
    static ISSUED_CASH_ON_DELIVERY_BD           = 10;  // Оформлен-наложенный платеж + БД (предворительно оплата + часть при получении или оплата 100% при получении посылки без учета доставки)
    static ARRANGEMENT_FOR_A_CALL               = 11;  // Договоренность о перезвоне
    static NO_ONE_PICKS_UP_THE_PHONE            = 12;  // Никто не берет трубку
    static CANCELLED                            = 13;  // Отменен
    static PARTIAL_PREPAYMENT                   = 14;  // Частичная предоплата
    static FULL_PREPAYMENT                      = 15;  // Полная предоплата
    static SENT                                 = 16;  // Отправлен
    static DELIVERED                            = 17;  // Забран
    static REFUSAL_TO_RECEIVE                   = 18;  // Отказ от получения
    static EDITING                              = 19;  // Редактирование
    static IN_TRANSIT                           = 20;  // В пути
    static IN_THE_DEPARTMENT                    = 21;  // В отделении
    static FREE_ORDER                           = 22;  // Бесплатный заказ
    static TIME_DIFFERENCE                      = 23;  // Разница во времени
    static DIDN_T_GET_THROUGH_NBT4              = 24;  // Не дозвонились (НБТ4)
    static AWAITING_ONLINE_PAYMENT              = 25;  // Ожидает онлайн оплату
    static NOT_PAID_ONLINE                      = 26;  // Не оплачен онлайн
    static AWAITING_ONLINE_PAYMENT_BD           = 27;  // Ожидает онлайн оплату + БД
    static FULL_PREPAYMENT_TO_THE_CARD          = 28;  // Полная предоплата на карту
    static REFUSAL_EXPENSIVE_DELIVERY           = 29;  // Отказ дорогая доставка
    static AUTOMATIC_DIALING_OF_A_NEW_ORDER     = 30;  // Автоматический дозон нового заказа
    
    static getListWithNames() {
        return [
              { name: "Проверка телефона", value: this.CHECK_PHONE }
            , { name: "Новый", value: this.NEW_ORDER }
            , { name: "В обработке", value: this.IN_PROGRESS }
            , { name: "Некорректный номер", value: this.INVALID_NUMBER }
            , { name: "Дубль", value: this.DOUBLE }
            , { name: "Заказ для связи", value: this.ORDER_FOR_COMMUNICATION }
            , { name: "Оформлен-оплачен", value: this.ISSUED_PAID_FOR }
            , { name: "Оформлен-оплачен + БД", value: this.ISSUED_PAID_FOR_BD }
            , { name: "Оформлен-наложенный платеж", value: this.ISSUED_CASH_ON_DELIVERY }
            , { name: "Оформлен-наложенный платеж + БД", value: this.ISSUED_CASH_ON_DELIVERY_BD }
            , { name: "Договоренность о перезвоне", value: this.ARRANGEMENT_FOR_A_CALL }
            , { name: "НБТ", value: this.NO_ONE_PICKS_UP_THE_PHONE }
            , { name: "Отменен", value: this.CANCELLED }
            , { name: "Частичная предоплата", value: this.PARTIAL_PREPAYMENT }
            , { name: "Полная предоплата", value: this.FULL_PREPAYMENT }
            , { name: "Отправлен", value: this.SENT }
            , { name: "Забран", value: this.DELIVERED }
            , { name: "Отказ от получения", value: this.REFUSAL_TO_RECEIVE }
            , { name: "Редактирование", value: this.EDITING }
            , { name: "В пути", value: this.IN_TRANSIT }
            , { name: "В отделении", value: this.IN_THE_DEPARTMENT }
            , { name: "Бесплатный заказ", value: this.FREE_ORDER }
            , { name: "Разница во времени", value: this.TIME_DIFFERENCE }
            , { name: "Не дозвонились (НБТ4)", value: this.DIDN_T_GET_THROUGH_NBT4 }
            , { name: "Ожидает онлайн оплату", value: this.AWAITING_ONLINE_PAYMENT }
            , { name: "Не оплачен онлайн", value: this.NOT_PAID_ONLINE }
            , { name: "Ожидает онлайн оплату (БД)", value: this.AWAITING_ONLINE_PAYMENT_BD }
            , { name: "Полная предоплата на карту", value: this.FULL_PREPAYMENT_TO_THE_CARD }
            , { name: "Отказ дорогая доставка", value: this.REFUSAL_EXPENSIVE_DELIVERY }
            , { name: "Автоматический дозон нового заказа", value: this.AUTOMATIC_DIALING_OF_A_NEW_ORDER }
        ]
    }
    
    static getNameByType(type) {
        let list = this.getListWithNames();
        for(let item of list) {
            if( type == item.value ) {
                return item.name;
            }
        }
        
        return '';
    }
}