<!-- Компонент анимации процесса -->
<template>
    <div id="loading-div-root" v-show="visible">
        <div id="loading-div-fade" v-show="visible"></div>
        <div id="loading-div-info-progress" v-show="visible">
            <div>
                <div id="loading-div-icon"></div>
                <span id="loading-div-info-progres-txt" class="noselect">{{ loadingText }}</span>
            </div>
            <div v-if="textCancel.length > 0" id="loading-div-info-progress_footer"><span v-on:click="cancel()" id="loading-div-info-progress_btn">{{ textCancel }}</span></div>
        </div>
    </div>
</template>

<script>
import { inject } from 'vue'
    
export default {
    data() {
        return {
            loadingText: 'Обработка...',
            visible: false,
            textCancel: '',
        }
    },
    created() {
        let globalConfig = inject('globalConfig');
        
        globalConfig.$bus.offAll('showLoading');
        globalConfig.$bus.offAll('hideLoading');
        
        globalConfig.$bus.on('showLoading', (newText, cancel)=> {
            this.showLoading(newText, cancel);
        });
        globalConfig.$bus.on('hideLoading', ()=> {
            this.hideLoading();
        });
        globalConfig.$bus.on('loadingChangeText', (_text)=> {
            let text         = _text ?? "Обработка...";
            this.loadingText = text;
        });
    },
    methods: {
        showLoading(_text, _cancel) {
            let text        =  _text ?? "Обработка...";
            this.textCancel =  _cancel ?? "";

            this.visible = true;
            this.loadingText = text;

        },
        hideLoading() {
            this.visible = false;
        },
        cancel() {
            this.$bus.emit('cancelEvent');
        }
    }
};
</script>

<style scoped>
    #loading-div-icon {
        background: url(~@/assets/images/ajax-loader.gif) no-repeat;
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 7px;
    }
    #loading-div-root {
        position: fixed;
        z-index: 1110;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    #loading-div-fade {
        background: #000;
        position: fixed; left: 0; top: 0;
        width: 100%; height: 100%;
        opacity:0.2; -khtml-opacity:0.2; filter:alpha(opacity=20);
        z-index: 1109;
    }
    #loading-div-info-progress {
        z-index: 1110;
        background: #FFF;
        border: 1px solid #CCC;
        border-radius: 5px;
        padding: 8px 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #loading-div-info-progress > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #loading-div-info-progress span {
        font-size: 14px;
    }

    #loading-div-info-progress_btn {
        background: #2D87E2;
        border-radius: 5px;
        overflow: hidden;
        color: #FFFFFF;
        padding: 5px 10px;
        cursor: pointer;
        text-decoration: none;
    }
    #loading-div-info-progress_btn:hover {
        background: #317bc8;
    }
    #loading-div-info-progress_footer {
        margin: 10px 0 5px 0;
    }
</style>