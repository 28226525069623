/* Категории продукции системы */
export default class {
    static WOMEN_PARFUM     = 1;  // Женский парфюм
    static MEN_PARFUM       = 2;  // Мужской парфюм
    static AUTOPARFUMS      = 3;  // Автопарфюм
    static SALES_HITS       = 4;  // Хиты продаж
    
    static getListWithNames() {
        return [
              { name: "Женский парфюм", value: this.WOMEN_PARFUM }
            , { name: "Мужской парфюм", value: this.MEN_PARFUM }
            , { name: "Автопарфюмы", value: this.AUTOPARFUMS }
            , { name: "Хиты продаж", value: this.SALES_HITS }
        ]
    }
    
    static getNameByType(v) {
        let list = this.getListWithNames();
        for(let item of list) {
            if( v == item.value ) {
                return item.name;
            }
        }
        return '';
    }
}