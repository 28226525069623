import router from '@/router'
import { toast } from './toast.js'

if(!String.prototype.trim) {
    String.prototype.trim = function () {
        return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
    };
}

export const log = {
    error: console.error,
    warn: console.warn,
    info: console.log,
    trace: console.trace,
    debug: console.debug,
    time: console.time,
    timeEnd: console.timeEnd,
};

Object.defineProperty(Array.prototype, 'orderBy', {
    value: function(sorts) {
        sorts.map(sort => {            
            sort.uniques = Array.from(
                new Set(this.map(obj => obj[sort.key]))
            );

            sort.uniques = sort.uniques.sort((a, b) => {
                a = a ?? '';
                b = b ?? '';

                if (typeof a == 'string') {
                    return sort.inverse ? b.localeCompare(a) : a.localeCompare(b);
                }
                else if (typeof a == 'number') {
                    return sort.inverse ? b - a : a - b;
                }
                else if (typeof a == 'boolean') {
                    let x = sort.inverse ? (a === b) ? 0 : a? -1 : 1 : (a === b) ? 0 : a? 1 : -1;
                    return x;
                }
                return 0;
            });
        });

        const weightOfObject = (obj) => {
            let weight = "";
            sorts.map(sort => {
                let zeropad = `${sort.uniques.length}`.length;
                weight += sort.uniques.indexOf(obj[sort.key]).toString().padStart(zeropad, '0');
            });
            //obj.weight = weight; // if you need to see weights
            return weight;
        }

        this.sort((a, b) => {
            return weightOfObject(a).localeCompare( weightOfObject(b) );
        });

        return this;
    }
});

let isDev   = false; //process.env.NODE_ENV === 'development';
let host    = isDev ? 'http://localhost:3015/' : 'https://cashback100.ru:3015/';

export const HostName  = host;

/**
 * Глобальные константы
 */
export const g_config = {
    country: 'RU',
    countrys: ['RU', 'KZ', 'BY'],
    host: host,
    TIME_OFFSET_FROM_CREATE_DATE_IN_SEC: (new Date().getTimezoneOffset() + 180) * 60,      // При создании new Date, добавить
    TIME_OFFSET_TO_SEND_SERVER_IN_SEC:   (new Date().getTimezoneOffset() + 180) * 60 * -1, // При отпраке запросов на сервер
    
    urlLogin: host + 'auth/login',
    urlLogOut: host + 'auth/logout',
    
    urlUserInfo: host + 'get-user-info',
    urlBrendsLoadAll: host + 'products/brends-load-all',
    urlGetListOrders: host + 'orders/get-list-orders',
    
    urlKladr_getHistoryForSdek: host + 'sdek/get-deliver-info',
    urlApiPost_checkOrder: host + 'post/check-order',
};

/**
 * Обработка ошибки апи, которую вернул сам сервер, в json ответа
 */
export const apiErrorHandling = (data) => {
    if(data.error_key == 'error-access') { 
        toast.toast( { type: 'text', value: "Ошибка доступа" }, { settings: { duration: 5300 } });
        localStorage.removeItem('apiToken');
        router.push('/login');
        return;
    }
};

/**
 * Обработка ошибки запроса к апи
 */
const errorResponseHandling = (err, method, url, text, request_data = {}) => {
    
    //console.log(err, '-', err.name, '-', err.message, method, url, text);
    
    let printMessage = "Ошибка обработки запроса";
    
    if (err instanceof TypeError) {
        if(err.message.indexOf("NetworkError") != -1) {
            printMessage = "Ошибка сети";
        }
    }
    else if (err instanceof SyntaxError) {
        
        console.log(request_data);
        
        /*fetch(g_config.urlSendError, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization': 'Bearer ' + localStorage.token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                type: method,
                url: url,
                data:  navigator.userAgent 
                        + ' \n' + window.location 
                        + ' \n' + JSON.stringify(request_data) 
                        + ' \n' + text
            })
        });*/
    }

    toast.toast( { type: 'text', value: printMessage }, { settings: { duration: 5300 } });
};

/**
 * Запрос POST
 * 
 * @param {type} url
 * @param {type} request_data
 * @param {type} x_csrf_token
 * @returns {promice}
 */
export const g_postRequest = async (url = '', request_data = {}, token = '') => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(request_data)
        });
        
        const data = await response.json();
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'POST', url, text, request_data);
        return Promise.reject({type_error: 'other', data: err});
    }
};

/**
 * Запрос PUT
 * 
 * @param {type} url
 * @param {type} request_data
 * @param {type} x_csrf_token
 * @returns {unresolved}
 */
export const g_putRequest = async (url = '', request_data = {}, token = '') => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(request_data)
        });
    
        const data = await response.json();
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'PUT', url, text, request_data);
        return Promise.reject({type_error: 'other', data: err});
    }
};

/**
 * Запрос GET
 * 
 * @param {type} url
 * @param {type} token
 * @returns {unresolved}
 */
export const g_getRequest = async (url = '', token = '') => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        });
        
        const data = await response.json();
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'GET', url, text);
        return Promise.reject({type_error: 'other', data: err});
    }
};


/**
 * Запрос DELETE
 * 
 * @param {type} url
 * @param {type} token
 * @returns {unresolved}
 */
export const g_deleteRequest = async (url = '', token = '') => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        });
    
        const data = await response.json();
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'DELETE', url, text);
        return Promise.reject({type_error: 'other', data: err});
    }
};

/**
* Генеринует случайную строку из набора символов
*
* @param {type} length  - длинна нужной строки
* @returns {String}      
*/
export const getRandomString = (length) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for(let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

/**
 * Convert timestatm to date format hour:min dd.mm.YYYY
 * 
 * @param {type} UNIX_timestamp
 * @returns {String}
 */
export const timeConverter = (UNIX_timestamp, fullYear = false, needSeonds = false) => {
    let a = new Date(UNIX_timestamp * 1000);
    let year = fullYear ? a.getFullYear() : a.getFullYear().toString().slice(-2);
    let month = '' + (a.getMonth() + 1);
    let date = '' + a.getDate();
    let hour = '' + a.getHours();
    let min = '' + a.getMinutes();

    if (month.length < 2) month = '0' + month;
    if (date.length < 2)  date = '0' + date;
    if (hour.length < 2)  hour = '0' + hour;
    if (min.length < 2)   min = '0' + min;
    
    if(needSeonds) {
        let sec = '' + a.getSeconds();
        if (sec.length < 2)   sec = '0' + sec;
        
        return hour + ':' + min + ':' + sec + ' ' + date + '.' + month + '.' + year;
    }
    else {
        return hour + ':' + min + ' ' + date + '.' + month + '.' + year;
    }
}

/**
 * Convert timestatm to date format hour:min dd.mm.YYYY
 * 
 * @param {type} UNIX_timestamp
 * @returns {String}
 */
export const timeConverterToDate = (UNIX_timestamp) => {
    let a = new Date(UNIX_timestamp * 1000);
    let year  = a.getFullYear();
    let month = '' + (a.getMonth() + 1);
    let date  = '' + a.getDate();

    if (month.length < 2) month = '0' + month;
    if (date.length < 2)  date = '0' + date;

    return date + '.' + month + '.' + year;
}

/**
 * Convert timestatm to date format hour:min
 * 
 * @param {type} UNIX_timestamp
 * @returns {String}
 */
export const timeConverterHourMinute = (UNIX_timestamp) => {
    let a = new Date(UNIX_timestamp * 1000);
    let hour = '' + a.getHours();
    let min = '' + a.getMinutes();

    if (hour.length < 2)  hour = '0' + hour;
    if (min.length < 2)   min = '0' + min;

    return hour + ':' + min;
}

//Транслитерация кириллицы в URL
export const urlRusLat = (str) => {
    str = str.toLowerCase(); // все в нижний регистр
    let cyr2latChars = new Array(
       ['а', 'a'], ['б', 'b'], ['в', 'v'],  ['г', 'g'],
       ['д', 'd'], ['е', 'e'], ['ё', 'yo'], ['ж', 'zh'], ['з', 'z'],
       ['и', 'i'], ['й', 'y'], ['к', 'k'], ['л', 'l'],
       ['м', 'm'], ['н', 'n'], ['о', 'o'], ['п', 'p'],  ['р', 'r'],
       ['с', 's'], ['т', 't'], ['у', 'u'], ['ф', 'f'],
       ['х', 'h'], ['ц', 'c'], ['ч', 'ch'],['ш', 'sh'], ['щ', 'shch'],
       ['ъ', ''],  ['ы', 'y'], ['ь', ''],  ['э', 'e'], ['ю', 'yu'], ['я', 'ya'],
       ['А', 'A'], ['Б', 'B'],  ['В', 'V'], ['Г', 'G'],
       ['Д', 'D'], ['Е', 'E'],  ['Ё', 'YO'], ['Ж', 'ZH'], ['З', 'Z'],
       ['И', 'I'], ['Й', 'Y'],  ['К', 'K'], ['Л', 'L'],
       ['М', 'M'], ['Н', 'N'],  ['О', 'O'], ['П', 'P'], ['Р', 'R'],
       ['С', 'S'], ['Т', 'T'],  ['У', 'U'], ['Ф', 'F'],
       ['Х', 'H'], ['Ц', 'C'], ['Ч', 'CH'], ['Ш', 'SH'], ['Щ', 'SHCH'],
       ['Ъ', ''],  ['Ы', 'Y'],
       ['Ь', ''],
       ['Э', 'E'],
       ['Ю', 'YU'],
       ['Я', 'YA'],
       ['a', 'a'], ['b', 'b'], ['c', 'c'], ['d', 'd'], ['e', 'e'],
       ['f', 'f'], ['g', 'g'], ['h', 'h'], ['i', 'i'], ['j', 'j'],
       ['k', 'k'], ['l', 'l'], ['m', 'm'], ['n', 'n'], ['o', 'o'],
       ['p', 'p'], ['q', 'q'], ['r', 'r'], ['s', 's'], ['t', 't'],
       ['u', 'u'], ['v', 'v'], ['w', 'w'], ['x', 'x'], ['y', 'y'],
       ['z', 'z'],
       ['A', 'A'], ['B', 'B'], ['C', 'C'], ['D', 'D'], ['E', 'E'],
       ['F', 'F'], ['G', 'G'], ['H', 'H'], ['I', 'I'], ['J', 'J'], ['K', 'K'],
       ['L', 'L'], ['M', 'M'], ['N', 'N'], ['O', 'O'], ['P', 'P'],
       ['Q', 'Q'], ['R', 'R'], ['S', 'S'], ['T', 'T'], ['U', 'U'], ['V', 'V'],
       ['W', 'W'], ['X', 'X'], ['Y', 'Y'], ['Z', 'Z'],
       [' ', '-'], ['0', '0'], ['1', '1'], ['2', '2'], ['3', '3'],
       ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7'], ['8', '8'], ['9', '9'],
       ['-', '-']
    );

    let newStr = new String();
    for (let i = 0; i < str.length; i++)
    {
        let ch = str.charAt(i);
        let newCh = '';
        for (let j = 0; j < cyr2latChars.length; j++)
        {
            if (ch == cyr2latChars[j][0])
            {
                newCh = cyr2latChars[j][1];
            }
        }
        // Если найдено совпадение, то добавляется соответствие, если нет - пустая строка
        newStr += newCh;
    }
    // Удаляем повторяющие знаки - Именно на них заменяются пробелы.
    // Так же удаляем символы перевода строки, но это наверное уже лишнее
    return newStr.replace(/[_]{2,}/gim, '_').replace(/\n/gim, '');
}

export const capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
}

export const dateToString = (a) => {
    let year = a.getFullYear();
    let month = '' + (a.getMonth() + 1);
    let date = '' + a.getDate();

    if (month.length < 2) month = '0' + month;
    if (date.length < 2)  date = '0' + date;

    return date + '.' + month + '.' + year;
}

/**
 * @example getCorrectSuffix(10, "день","дня", "дней")
 */
export const getCorrectSuffix = (num, str1, str2, str3) => {
    var val = num % 100;
    
    if (val > 10 && val < 20)
    {
      return str3;
    }
    else
    {
      val = num % 10;
      if (val == 1)
      { return str1; }	    
      else if (val > 1 && val < 5)
      { return str2; }
      else
      { return str3; }
    }
}

export const  isEmpty = (str) => {
    return (!str || str.length === 0 );
}

export const buildListTime = () => {
    let result = [];
    for(let hour = 0; hour < 24; hour += 1) {
        for(let min = 0; min < 60; min += 30) {
            result.push( 
                      ((hour < 10) ? '0' + hour : hour)
                    + ':' 
                    + ((min < 10) ? '0' + min : min)
                );
        }
    }

    return result;
}

/**
 * 
 * @param {type} value dd.mm.YYYY
 * @returns {Date}
 */
export const strToDate_ddmmYYYY = (value) => {
    let m      = value.match(/^(\d{2})\.(\d{2})\.(\d{4})/);
    
    if(! m) {
        return 0;
    }
    
    let day    = parseInt( m[1] );
    let month  = parseInt( m[2] );
    let year   = parseInt( m[3] );
    
    return new Date(year, month - 1, day, 0, 0, 0, 0);
}

export const validateDate_ddmmYYYY = (value) => {
    return /^\d{2}\.\d{2}\.\d{4}/.test(value);
}

export const printDiffTime = (unixTimestamp) => {
    
    let result = '';
    let now   = Math.floor(Date.now() / 1000);
    let diff  = now - unixTimestamp;
    
    let d = parseInt( diff / ( 24 * 60 * 60 ) );
    let h = parseInt( (diff - d * 24 * 60 * 60) / ( 60 * 60 ) );
    let m = parseInt( (diff - d * 24 * 60 * 60 - h * 60 * 60) / ( 60 ) );
    let s = (diff - d * 24 * 60 * 60 - h * 60 * 60 - m * 60);

    if( d > 0 ) {
      if( d < 10 ) { result += '0'; }
      result += d + ':';
    }

    if(! (d == 0 && h == 0 )) {
      if( h < 10 ) { result += '0'; }
      result += h + ':';
    }

    if(! (d == 0 && h == 0 && m == 0 )) {
      if( m < 10 ) { result += '0'; }
      result += m + ':';
    }

    if( s < 10 ) { result += '0'; }
    result += s;
    
    return result;
}

export const getUnixTimestamp = () => {
    return Math.floor(Date.now() / 1000);
}

export const numbersOnly = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
    } else {
        return true;
    }
}

export const replaceCharsEngToRus = (str) => {
    let map = {
        'q' : 'й', 'w' : 'ц', 'e' : 'у', 'r' : 'к', 't' : 'е', 'y' : 'н', 'u' : 'г', 'i' : 'ш', 'o' : 'щ', 'p' : 'з', /*'[' : 'х', ']' : 'ъ',*/ 'a' : 'ф', 's' : 'ы', 'd' : 'в', 'f' : 'а', 'g' : 'п', 'h' : 'р', 'j' : 'о', 'k' : 'л', 'l' : 'д', /*';' : 'ж', '\'' : 'э',*/ 'z' : 'я', 'x' : 'ч', 'c' : 'с', 'v' : 'м', 'b' : 'и', 'n' : 'т', 'm' : 'ь', /*',' : 'б', '.' : 'ю',*/ 'Q' : 'Й', 'W' : 'Ц', 'E' : 'У', 'R' : 'К', 'T' : 'Е', 'Y' : 'Н', 'U' : 'Г', 'I' : 'Ш', 'O' : 'Щ', 'P' : 'З', '[' : 'Х', ']' : 'Ъ', 'A' : 'Ф', 'S' : 'Ы', 'D' : 'В', 'F' : 'А', 'G' : 'П', 'H' : 'Р', 'J' : 'О', 'K' : 'Л', 'L' : 'Д', ';' : 'Ж', '\'' : 'Э', 'Z' : '?', 'X' : 'ч', 'C' : 'С', 'V' : 'М', 'B' : 'И', 'N' : 'Т', 'M' : 'Ь', ',' : 'Б', '.' : 'Ю'
    };
    
    let r = '';
    for(let i = 0; i < str.length; i++) {
        r += map[str.charAt(i)] || str.charAt(i);
    }
    
    return r;
}

export const replaceCharsRusToEng = (str) => {
    let map = {
        'й' : 'q', 'ц' : 'w', 'у' : 'e', 'к' : 'r', 'е' : 't', 'н' : 'y', 'г' : 'u', 'ш' : 'i', 'щ' : 'o', 'з' : 'p', 'х' : '[', 'ъ' : ']', 'ф' : 'a', 'ы' : 's', 'в' : 'd', 'а' : 'f', 'п' : 'g', 'р' : 'h', 'о' : 'j', 'л' : 'k', 'д' : 'l', 'ж' : ';', 'э' : '\'', 'я' : 'z', 'ч' : 'x', 'с' : 'c', 'м' : 'v', 'и' : 'b', 'т' : 'n', 'ь' : 'm', 'б' : ',', 'ю' : '.', 'Й' : 'Q', 'Ц' : 'W', 'У' : 'E', 'К' : 'R', 'Е' : 'T', 'Н' : 'Y', 'Г' : 'U', 'Ш' : 'I', 'Щ' : 'O', 'З' : 'P', 'Х' : '[', 'Ъ' : ']', 'Ф' : 'A', 'Ы' : 'S', 'В' : 'D', 'А' : 'F', 'П' : 'G', 'Р' : 'H', 'О' : 'J', 'Л' : 'K', 'Д' : 'L', 'Ж' : ';', 'Э' : '\'', '?' : 'Z', 'Ч' : 'X', 'С' : 'C', 'М' : 'V', 'И' : 'B', 'Т' : 'N', 'Ь' : 'M', 'Б' : ',', 'Ю' : '.'
    };
    
    let r = '';
    for(let i = 0; i < str.length; i++) {
        r += map[str.charAt(i)] || str.charAt(i);
    }
    
    return r;
}

export const secondsToString = (v) => {
    if( v < 0 ) {
        return "00:00";
    }

    let min = Math.floor(v / 60);
    let sec = Math.floor(v - ( min * 60 ));

    return (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '') + sec;
}

/**
 * Convert timestatm to date format dd.mm.YYYY hour:min
 * 
 * @param {type} UNIX_timestamp
 * @returns {String}
 */
export const timeConverter_2 = (UNIX_timestamp) => {
    let a = new Date(UNIX_timestamp * 1000);
    let year = a.getFullYear();
    let month = '' + (a.getMonth() + 1);
    let date = '' + a.getDate();
    let hour = '' + a.getHours();
    let min = '' + a.getMinutes();

    if (month.length < 2) month = '0' + month;
    if (date.length < 2)  date = '0' + date;
    if (hour.length < 2)  hour = '0' + hour;
    if (min.length < 2)   min = '0' + min;

    return date + '.' + month + '.' + year + ' ' + hour + ':' + min;
}

// подсветка телефона
export const getHtmlHighlightPhone = (phone, search) => {

    if(phone == null) {
        return null;
    }

    if( phone.length == search.length ) {
        if(phone == search) {
            return '<strong>' + phone + '</strong>';
        }
    }
    else {
        for(let i = 0; i < phone.length - search.length; i++) {

            let find = false;
            let k = i;

            for(let j = 0; j < search.length;) {
                if( 
                        phone.charAt(k) == ' ' 
                     || phone.charAt(k) == '+' 
                ) {
                    k++;
                    continue;
                }
                else if( 
                        search.charAt(j) == ' ' 
                     || search.charAt(j) == '+' 
                ) {
                    j++;
                    continue;
                }
                else if(search.charAt(j) != phone.charAt(k)) {
                    find = false;
                    break;
                }
                else if(search.charAt(j) == phone.charAt(k)) { 
                    find = true;
                }
                j++;
                k++;
            }

            if( find ) {
                return    phone.substring(0, i)
                        + '<strong>'
                        + phone.substring(i, k)
                        + '</strong>'
                        + phone.substring(k);
            }
        }
    }

    return phone;
}

// Converts numeric degrees to radians
function toRad(Value) {
    return Value * Math.PI / 180;
}

export const calcDistanceMapPoints = (lat1, lon1, lat2, lon2) => {
  let R = 6371; // km
  let dLat = toRad(lat2-lat1);
  let dLon = toRad(lon2-lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  let d = R * c;
  return Math.round(d * 1000);
}

// distance в метрах
// bearing - направление (360 градусов)
export const calcEndPoint = (lat1, lon1, distance, bearing) => {
    let R = 6371000; // meters , earth Radius approx
    let PI = 3.1415926535;
    let RADIANS = PI / 180;
    let DEGREES = 180 / PI;

    lat1         = lat1 * RADIANS;
    lon1         = lon1 * RADIANS;
    let radbear  = bearing * RADIANS;

   // System.out.println("lat1="+lat1 + ",lon1="+lon1);

    let lat2 = Math.asin( Math.sin(lat1)*Math.cos(distance / R) +
            Math.cos(lat1)*Math.sin(distance/R)*Math.cos(radbear) );
    let lon2 = lon1 + Math.atan2(Math.sin(radbear)*Math.sin(distance / R)*Math.cos(lat1),
                   Math.cos(distance/R)-Math.sin(lat1)*Math.sin(lat2));

    return [lat2 * DEGREES, lon2 * DEGREES];
}

export const copyTextToClipboard = (text) => {
    let textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = 'transparent';


    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      /*let successful = */document.execCommand('copy');
      //let msg = successful ? 'successful' : 'unsuccessful';
      //console.log('Copying text command was ' + msg);
    } catch (err) {
      //console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
}

export const truncString = (str, max, add = '...') => {
    return (typeof str === 'string' && str.length > max ? str.substring(0,max)+add : str);
}

export const pagesCalcList = (current, countPages, N = 10, NearCount = 3) => {
    
    if(countPages <= N) {
        return Array.from({length: countPages}, (item, index) => index);
    }
    else {
        let result = [];

        for(let i = 0; i < countPages; i++) {
            if(i === 0) {
                result.push(i);
            }

            if(i === countPages - 1) {
                for(let index = current - NearCount; index <= current; index++) {
                    if(index >= 0) {
                        result.push(index);
                    }
                }

                result.push(i);
            }

            if(i === current) {
                let countAdd = 0;

                for(let index = current - NearCount; index <= current; index++) {
                    if(index >= 0) {
                        result.push(index);
                    }
                    else {
                        countAdd++;
                    }
                }

                for(let index = 0; index < countAdd; index++) {
                    result.push(current + 1 + index);
                }

                for(let index = current + 1; index <= countPages && index < current + 1 + NearCount; index++) {
                    result.push(index);
                }
            }
        }

        result = [...new Set(result)];

        let res = [];

        for(let i = 0, f = 0; i < countPages; i++) {
            if(i == result[f]) {
                f++;
                res.push(i);
            }
            else if(res[ res.length - 1 ] != '...') {
                res.push('...');
            }
        }

        return res;
    }
}

export const checkIsValidDomain = (domain) => { 
    var re = new RegExp(/^((?:(?:(?:\w[.\-+]?)*)\w)+)((?:(?:(?:\w[.\-+]?){0,62})\w)+)\.(\w{2,6})$/); 
    return domain.match(re);
} 