<!-- Страница не найдена -->

<template>
    <div id="notFoundPage">
        <h2>Страница не найдена</h2>
    </div>
</template>

<script setup>
    document.title = "Страница не найдена";
</script>

<style scoped>
    h2 {
        margin: 25px;
        font-size: 16px;
    }
    #notFoundPage {
        display: flex;
        align-items: stretch;
        flex-direction: column;
    }
</style>